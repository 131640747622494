import Link from "next/link";
import { useRouter } from "next/router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IProduct } from "../../model/product";
import styles from './product-small-tile.module.css';
export default function ProductSmallTile(props: {product: IProduct, size: 'normal' | 'xs', detailsPath: string}) {
	const router = useRouter();
	let stars = '&#9734;&#9734;&#9734;&#9734;&#9734;';
	if (props.product.rating){
		stars = '';
		for(let i = 0; i <= 4; i++) {
			if (i <= props.product.rating) {
				stars += '&#9733;';
			} else {
				stars += '&#9734;';
			}
		}
	}

	
	if (props.product.rating == -1) {
		stars = '&nbsp;';
	}

	return (
		<Link href={`${props.detailsPath?.toLowerCase()}/${props.product.urlName}`}>
			<div key={props.product?._id} className={styles.container}>
				<div className={styles.headerContainer} style={{height: '72px', display: 'flex', alignItems: 'center', paddingTop: '8px'}}>{props.product.name}</div>
				<div style={{height: '172px'}}>
				<LazyLoadImage className={styles.image} style={{background: '#fff'}} src={props.product.image} alt={props.product.name} /><br/>
				</div>
				<div style={{flexGrow: 2}}>
					<div className={styles.readMoreBtn} style={{display: 'inline'}}>
						Fra {props.product?.priceStr}
					</div>
				</div>
			</div>
		</Link>
	)
}